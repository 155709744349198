import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '../../contexts/Auth';

import background from '../../resources/img/backgrounds/login.png';
import loginShape from '../../resources/img/backgrounds/login_shape.svg';
import loginShape2 from '../../resources/img/backgrounds/login_shape_2.svg';

import SolidButton from '../../components/SolidButton';
import { showErrorToast, showSuccessToast } from '../../components/Toast';

export default function Login() {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  const [loading, setLoading] = useState(false);
  const [accessValue, setAccessValue] = useState('');
  const [password, setPassword] = useState('');

  const [title, setTitle] = useState('INICIAR SESIÓN');

  useEffect(() => {
    // Extraer el parámetro 'email' de la URL si existe
    const params = new URLSearchParams(location.search);
    const email = params.get('email');
    if (email) {
      setAccessValue(email);
      setTitle('USTED YA SE ENCUENTRA REGISTRADO');
      // Eliminar el parámetro "email" de la URL
      params.delete('email');
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
    }
  }, [location.search]);

  let login = async () => {
    setLoading(true);
    let result = await auth.signin(accessValue, password);
    if (!result.success) {
      setLoading(false);
      return showErrorToast(result.message);
    }
    showSuccessToast(result.message);
    history.replace(result?.data?.redirect || '/profile');
  };

  return (
    <div
      className="justify-top relative flex min-h-screen flex-col bg-cover"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <div
        className="absolute top-0 left-0 z-0 hidden h-full w-full max-w-2xl bg-no-repeat md:block lg:max-w-3xl xl:max-w-5xl"
        style={{ backgroundImage: `url(${loginShape})` }}
      />
      <div
        className="absolute bottom-0 right-0 z-0 hidden h-full w-full max-w-xl bg-right bg-no-repeat md:block"
        style={{ backgroundImage: `url(${loginShape2})` }}
      />
      <div className="z-10 py-20 sm:ml-24 sm:w-full sm:max-w-lg sm:px-2">
        <div className="bg-white px-4 py-11 shadow sm:rounded-2xl sm:px-12">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="text-center text-2xl font-light text-primary">
              {title}
            </h2>
          </div>
          <div className="mt-16">
            <div>
              <label htmlFor="inp_access_value" className="sr-only">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="inp_access_value"
                  name="access_value"
                  type="email"
                  placeholder="Correo estudiante"
                  value={accessValue}
                  autoFocus
                  onChange={(e) => setAccessValue(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') login();
                  }}
                  className="inp-base"
                ></input>
              </div>
            </div>

            <div className="mt-4">
              <label htmlFor="inp_password" className="sr-only">
                Contraseña
              </label>
              <div className="mt-1">
                <input
                  id="inp_password"
                  name="password"
                  type="password"
                  placeholder="Contraseña"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') login();
                  }}
                  className="inp-base"
                ></input>
              </div>
            </div>

            <div className="mt-8">
              <SolidButton onClick={login} isLoading={loading}>
                INGRESAR
              </SolidButton>
            </div>

            <div className="mt-4 flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                />
                <label
                  htmlFor="remember_me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Recordar mi sesión
                </label>
              </div>
              <div className="text-sm">
                <Link
                  to="/recover_password"
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  Olvidé mi contraseña
                </Link>
              </div>
            </div>
            <div className="mt-12 mb-4">
              <SolidButton color="yellow" href="/signup">
                REGÍSTRATE
              </SolidButton>
            </div>
            <div className="mt-4 mb-4">
              si no recuerdas tu contraseña, darle clic en olvidé mi contraseña
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
