import { useEffect, useMemo, useState } from 'react';
import { Transition } from '@headlessui/react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { differenceInCalendarYears } from 'date-fns';
import { useAuth } from '../../contexts/Auth';

import GeneralApi from '../../utils/generalApi';

import background from '../../resources/img/backgrounds/signup.png';
import signupShape from '../../resources/img/backgrounds/signup_shape.svg';
import signupShape2 from '../../resources/img/backgrounds/signup_shape_2.svg';

import OutlineButton from '../../components/OutlineButton';
import SolidButton from '../../components/SolidButton';
import { showErrorToast, showSuccessToast } from '../../components/Toast';
import ErrorMessage from '../../components/ErrorMessage';
import axios from 'axios';
export default function NewSignup() {
  let history = useHistory();
  let auth = useAuth();

  const {
    watch,
    register,
    getValues,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      date_of_birth: new Date().toISOString().slice(0, 10),
    },
  });

  const docType = watch('doc_type', '');
  const studyLevel = watch('study_level', '');
  const studyGrade = watch('study_grade', '');
  const birthDate = watch(
    'date_of_birth',
    new Date().toISOString().slice(0, 10)
  );
  const watchDepartment = watch('department', '');
  const watchProvince = watch('province', '');

  const proxyDocType = watch('proxy_doc_type', '');

  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showProxyForm, setShowProxyForm] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);

  const needsProxy = useMemo(
    () =>
      birthDate &&
      differenceInCalendarYears(new Date(), new Date(birthDate)) < 20,
    [birthDate]
  );

  const generalApi = new GeneralApi(auth, history);

  let checkDNIBK = async (prefix) => {
    setCheckLoading(true);
    const result = await generalApi.post(`/validateDNI`, {
      dni: getValues(`${prefix}doc_number`),
    });
    if (!result.success) {
      showErrorToast(result.message);
      setCheckLoading(false);
      return;
    }
    setValue(`${prefix}name`, result.data.names);
    setValue(`${prefix}last_name_p`, result.data.first_name);
    setValue(`${prefix}last_name_m`, result.data.last_name);
    trigger([`${prefix}name`, `${prefix}last_name_p`, `${prefix}last_name_m`]);
    setCheckLoading(false);
  };

  let checkDNI = async (prefix) => {
    try {
      setCheckLoading(true);

      const response = await axios.post(
        'https://admin.edtlab.edu.pe/api/classroom/signup/validate',
        {
          doc_type:  getValues(`${prefix}doc_type`),
          doc_number:  getValues(`${prefix}doc_number`)
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.status === 200) {
        const resultNew = response.data;
  
        if (resultNew.student) {
          setCheckLoading(false);
          setValue(`${prefix}name`, resultNew.student.name);
          setValue(`${prefix}last_name_p`, resultNew.student.last_name_p);
          setValue(`${prefix}last_name_m`, resultNew.student.last_name_m);
          setValue(`${prefix}phone`, resultNew.student.phone);
          setValue(`${prefix}email`, resultNew.student.email);
  
          setValue(`${prefix}study_level`, resultNew.student.study_level);
          setValue(`${prefix}study_grade`, resultNew.student.study_grade);
          setValue(`${prefix}study_college`, resultNew.student.study_college);
          setValue(`${prefix}study_career`, resultNew.student.study_career);
          setValue(`${prefix}genre`, resultNew.student.genre);
  
          trigger([`${prefix}name`, `${prefix}last_name_p`, `${prefix}last_name_m`]);
          alert("usted ya se encuentra registardo con los siguientes datos.")

          //window.location.href = "/signup";
          history.replace('/?email='+resultNew.student.email);
        }
      }
  
      setCheckLoading(true);
      const response2 = await axios.post(
        'https://edtlab-cms-dev.herokuapp.com/api/admin/reniec',
        {
          dni:  getValues(`${prefix}doc_number`)
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response2.status === 200) {
        const resultNew = response2.data;
  
        if (resultNew.success) {
          setCheckLoading(false);
          setValue(`${prefix}name`, resultNew.data?.nombres);
          setValue(`${prefix}last_name_p`, resultNew.data?.apellido_paterno);
          setValue(`${prefix}last_name_m`, resultNew.data?.apellido_materno);
        }
      }

      setCheckLoading(false);
  
    } catch (error) {
      setCheckLoading(false);
      console.log(error)
    }


    // const result = await generalApi.post(`/validateDNI`, {
    //   dni: getValues(`${prefix}doc_number`),
    // });
    // if (!result.success) {
    //   showErrorToast(result.message);
    //   setCheckLoading(false);
    //   return;
    // }
    // setValue(`${prefix}name`, result.data.names);
    // setValue(`${prefix}last_name_p`, result.data.first_name);
    // setValue(`${prefix}last_name_m`, result.data.last_name);
    // trigger([`${prefix}name`, `${prefix}last_name_p`, `${prefix}last_name_m`]);
    // setCheckLoading(false);


  };

  let checkDNIProxy = async (prefix) => {
    try {
    
      setCheckLoading(true);
      const response2 = await axios.post(
        'https://edtlab-cms-dev.herokuapp.com/api/admin/reniec',
        {
          dni:  getValues(`${prefix}doc_number`)
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response2.status === 200) {
        const resultNew = response2.data;
  
        if (resultNew.success) {
          setCheckLoading(false);
          setValue(`${prefix}name`, resultNew.data?.nombres);
          setValue(`${prefix}last_name_p`, resultNew.data?.apellido_paterno);
          setValue(`${prefix}last_name_m`, resultNew.data?.apellido_materno);
        }
      }

      setCheckLoading(false);
  
    } catch (error) {
      setCheckLoading(false);
      console.log(error)
    }

  };

  let signup = async (data) => {
    let isOk = await trigger([
      'address',
      'date_of_birth',
      'department',
      'district',
      'doc_number',
      'doc_type',
      'email',
      'name',
      'genre',
      'last_name_m',
      'last_name_p',
      'phone',
      'province',
      'study_career',
      'study_college',
      'study_grade',
      'study_level',
    ]);
    if (!isOk) return;
    if (!acceptTerms)
      return showErrorToast('Debe aceptar los términos y condiciones');

    setLoading(true);

    const result = await generalApi.post(`/classroom/signup/new`, {
      ...data,
      doc_number: data.doc_number.trim(),
      proxy_doc_number: data.proxy_doc_number
        ? data.proxy_doc_number.trim()
        : undefined,
      department: {
        id: data.department.split(['|'])[0],
        name: data.department.split(['|'])[1],
      },
      province: {
        id: data.province.split(['|'])[0],
        name: data.province.split(['|'])[1],
      },
      distrite: {
        id: data.district.split(['|'])[0],
        name: data.district.split(['|'])[1],
      },
      needsProxy,
    });
    if (!result.success) {
      showErrorToast(result.message);
      setLoading(false);
      return;
    }
    showSuccessToast('Su cuenta ha sido creada, bienvenido');

    let loginResult = await auth.signin(
      getValues(needsProxy ? 'proxy_email' : 'email'),
      '123456'
    );
    if (!loginResult.success) {
      setLoading(false);
      return showErrorToast(loginResult.message);
    }
    history.replace(loginResult?.data?.redirect || '/profile');
  };

  useEffect(() => {
    getDeparments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (getValues('department') && getValues('department') !== '-')
      getProvinces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDepartment]);
  useEffect(() => {
    if (
      getValues('department') &&
      getValues('province') &&
      getValues('department') !== '-' &&
      getValues('province') !== '-'
    )
      getDistricts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchProvince]);

  let getDeparments = async () => {
    const result = await generalApi.get(`/departments`);
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    setDepartments(result.data);
  };
  let getProvinces = async () => {
    const result = await generalApi.get(
      `/provinces/${getValues('department').split(['|'])[0]}`
    );
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    setProvinces(result.data);
  };
  let getDistricts = async () => {
    const result = await generalApi.get(
      `/districts/${getValues('department').split(['|'])[0]}/${
        getValues('province').split(['|'])[0]
      }`
    );
    if (!result.success) {
      showErrorToast(result.message);
      return;
    }
    setDistricts(result.data);
  };

  return (
    <div
      className="justify-top relative min-h-screen bg-cover lg:flex lg:px-2 xl:px-6"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <div
        className="absolute top-0 left-0 z-0 hidden h-full w-full max-w-2xl bg-no-repeat lg:block lg:max-w-3xl xl:max-w-5xl"
        style={{ backgroundImage: `url(${signupShape})` }}
      />
      <div
        className="absolute bottom-0 right-0 z-0 hidden h-full w-full bg-right bg-no-repeat lg:block"
        style={{ backgroundImage: `url(${signupShape2})` }}
      />
      <div className="z-10 py-10 lg:w-1/2 lg:py-20 lg:px-2 xl:px-12">
        <div className="bg-white px-4 py-11 shadow lg:rounded-2xl lg:px-12">
          <div className="lg:mx-auto lg:w-full lg:max-w-md">
            <h2 className="text-center text-2xl font-light text-primary">
              REGISTRO
            </h2>
            <h2 className="text-center text-2xl font-light text-primary">
              DATOS DEL ESTUDIANTE
            </h2>
          </div>
          <div className="mt-8 gap-4 space-y-2 md:grid md:grid-cols-4 md:space-y-0">
            <div className="col-span-2">
              <label htmlFor="inp_doc_type" className="sr-only">
                Tipo de documento
              </label>
              <div className="mt-1">
                <select
                  id="inp_doc_type"
                  name="doc_type"
                  placeholder="Tipo de documento"
                  className="inp-base"
                  {...register('doc_type', {
                    required: true,
                  })}
                >
                  <option value="">Elegir tipo de documento</option>
                  <option>DNI</option>
                  <option>Pasaporte</option>
                  <option>Carnet de extranjería</option>
                  <option>RUC</option>
                </select>
              </div>
              <ErrorMessage errors={errors} field="doc_type" />
            </div>
            {docType === 'DNI' && <div className="col-span-2"></div>}
            <div className="col-span-2">
              <label htmlFor="inp_doc_number" className="sr-only">
                Número de documento
              </label>
              <div className="mt-1">
                <input
                  id="inp_doc_number"
                  name="doc_number"
                  type="text"
                  placeholder="Número de documento"
                  className="inp-base"
                  {...register('doc_number', {
                    required: true,
                  })}
                ></input>
              </div>
              <ErrorMessage errors={errors} field="doc_number" />
            </div>
            {docType === 'DNI' && (
              <div className="col-span-2">
                <OutlineButton
                  onClick={() => checkDNI('')}
                  isLoading={checkLoading}
                  disabled={loading}
                  color="light-blue"
                >
                  VALIDAR
                </OutlineButton>
              </div>
            )}
            <div className="col-span-2">
              <label htmlFor="inp_name" className="sr-only">
                Nombre
              </label>
              <div className="mt-1">
                <input
                  id="inp_name"
                  name="name"
                  type="text"
                  placeholder="Nombre"
                  className="inp-base"
                  {...register('name', {
                    required: true,
                  })}
                ></input>
              </div>
              <ErrorMessage errors={errors} field="name" />
            </div>
            <div className="col-span-2">
              <label htmlFor="inp_last_name_p" className="sr-only">
                Apellido Paterno
              </label>
              <div className="mt-1">
                <input
                  id="inp_last_name_p"
                  name="last_name_p"
                  type="text"
                  placeholder="Apellido Paterno"
                  className="inp-base"
                  {...register('last_name_p', {
                    required: true,
                  })}
                ></input>
              </div>
              <ErrorMessage errors={errors} field="last_name_p" />
            </div>
            <div className="col-span-2">
              <label htmlFor="inp_last_name_m" className="sr-only">
                Apellido Materno
              </label>
              <div className="mt-1">
                <input
                  id="inp_last_name_m"
                  name="last_name_m"
                  type="text"
                  placeholder="Apellido Materno"
                  className="inp-base"
                  {...register('last_name_m', {
                    required: true,
                  })}
                ></input>
              </div>
              <ErrorMessage errors={errors} field="last_name_m" />
            </div>
            <div className="col-span-2">
              <label htmlFor="inp_phone" className="sr-only">
                Celular
              </label>
              <div className="mt-1">
                <input
                  id="inp_phone"
                  name="phone"
                  type="text"
                  placeholder="Celular"
                  className="inp-base"
                  {...register('phone', {
                    required: true,
                  })}
                ></input>
              </div>
              <ErrorMessage errors={errors} field="phone" />
            </div>
            <div className="col-span-2">
              <label htmlFor="inp_email" className="sr-only">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="inp_email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  className="inp-base"
                  {...register('email', {
                    required: true,
                  })}
                ></input>
              </div>
              <ErrorMessage errors={errors} field="email" />
            </div>
            <div className="col-span-2">
              <label htmlFor="inp_genre" className="sr-only">
                Sexo
              </label>
              <div className="mt-1">
                <select
                  id="inp_genre"
                  name="genre"
                  placeholder="Sexo"
                  className="inp-base"
                  {...register('genre', { required: true })}
                >
                  <option value="">Elegir sexo</option>
                  <option>Masculino</option>
                  <option>Femenino</option>
                </select>
              </div>
              <ErrorMessage errors={errors} field="genre" />
            </div>
            <div className="col-span-2">
              <label htmlFor="inp_date_of_birth">Fecha de nacimiento</label>
              <div className="mt-1">
                <input
                  id="inp_date_of_birth"
                  name="date_of_birth"
                  type="date"
                  placeholder="Fecha de nacimiento"
                  className="inp-base relative"
                  {...register('date_of_birth', {
                    required: true,
                  })}
                ></input>
              </div>
              <ErrorMessage errors={errors} field="date_of_birth" />
            </div>
            <div className="col-span-2 md:pt-6">
              <label htmlFor="inp_study_level" className="sr-only">
                Nivel de Estudio
              </label>
              <div className="mt-1">
                <select
                  id="inp_study_level"
                  name="study_level"
                  placeholder="Nivel de Estudio"
                  className="inp-base"
                  {...register('study_level', { required: true })}
                >
                  <option value="">Elegir Nivel de Estudio</option>
                  <option>Inicial</option>
                  <option>Primaria</option>
                  <option>Secundaria</option>
                  <option>Pre-Universitario</option>
                  <option>Universitario</option>
                </select>
              </div>
              <ErrorMessage errors={errors} field="study_level" />
            </div>
            {!['Pre-Universitario', 'Universitario'].includes(studyLevel) && (
              <div className="col-span-2">
                <label htmlFor="inp_grade" className="sr-only">
                  Grado de Estudio
                </label>
                <div className="mt-1">
                  <select
                    id="inp_grade"
                    name="relation"
                    placeholder="Grado de Estudio"
                    className="inp-base"
                    {...register('study_grade', {
                      required: ![
                        'Pre-Universitario',
                        'Universitario',
                      ].includes(studyLevel),
                    })}
                  >
                    <option value="">Elegir Grado de Estudio</option>
                    {studyLevel === 'Inicial' ? (
                      <>
                        <option>3 años</option>
                        <option>4 años</option>
                        <option>5 años</option>
                      </>
                    ) : studyLevel === 'Primaria' ? (
                      <>
                        <option>1ero</option>
                        <option>2do</option>
                        <option>3ero</option>
                        <option>4to</option>
                        <option>5to</option>
                        <option>6to</option>
                      </>
                    ) : studyLevel === 'Secundaria' ? (
                      <>
                        <option>1ero</option>
                        <option>2do</option>
                        <option>3ero</option>
                        <option>4to</option>
                        <option>5to</option>
                      </>
                    ) : null}
                  </select>
                </div>
                <ErrorMessage errors={errors} field="study_grade" />
              </div>
            )}
            {(['Pre-Universitario', 'Universitario'].includes(studyLevel) ||
              (studyLevel === 'Secundaria' &&
                ['4to', '5to'].includes(studyGrade))) && (
              <>
                <div className="col-span-2">
                  <label htmlFor="inp_college" className="sr-only">
                    Universidad
                  </label>
                  <div className="mt-1">
                    <select
                      id="inp_college"
                      name="relation"
                      placeholder="Universidad"
                      className="inp-base"
                      {...register('study_college', {
                        required:
                          ['Pre-Universitario', 'Universitario'].includes(
                            studyLevel
                          ) ||
                          (studyLevel === 'Secundaria' &&
                            ['4to', '5to'].includes(studyGrade)),
                      })}
                    >
                      <option value="">Elegir Universidad</option>
                      <option>Pontificia Universidad Católica del Perú</option>
                      <option>Universidad Católica San Pablo</option>
                      <option>Universidad Científica del Sur</option>
                      <option>Universidad de Lima</option>
                      <option>Universidad de Piura</option>
                      <option>Universidad del Pacífico</option>
                      <option>Universidad ESAN</option>
                      <option>Universidad Nacional Agraria La Molina</option>
                      <option>Universidad Nacional de Ingeniería</option>
                      <option>
                        Universidad Nacional de la Amazonia Peruana
                      </option>
                      <option>
                        Universidad Nacional de San Agustín de Arequipa
                      </option>
                      <option>
                        Universidad Nacional de San Antonio Abad del Cusco
                      </option>
                      <option>Universidad Nacional de Trujillo</option>
                      <option>Universidad Nacional del Altiplano</option>
                      <option>Universidad Nacional Federico Villarreal</option>
                      <option>Universidad Nacional Mayor de San Marcos</option>
                      <option>Universidad Peruana Cayetano Heredia</option>
                      <option>Universidad Peruana de Ciencias Aplicadas</option>
                      <option>Universidad Privada del Norte</option>
                      <option>Universidad Ricardo Palma</option>
                      <option>Universidad San Ignacio de Loyola</option>
                      <option>Universidad San Martín de Porres</option>
                      <option>Otros</option>
                    </select>
                  </div>
                  <ErrorMessage errors={errors} field="study_college" />
                </div>

                <div className="col-span-2">
                  <label htmlFor="inp_study_career" className="sr-only">
                    Carrera
                  </label>
                  <div className="mt-1">
                    <select
                      id="inp_study_career"
                      name="study_career"
                      placeholder="Carrera"
                      className="inp-base"
                      {...register('study_career', {
                        required:
                          ['Pre-Universitario', 'Universitario'].includes(
                            studyLevel
                          ) ||
                          (studyLevel === 'Secundaria' &&
                            ['4to', '5to'].includes(studyGrade)),
                      })}
                    >
                      <option value="">Elegir Carrera</option>
                      <option>Administración</option>
                      <option>Antropología</option>
                      <option>Arqueología</option>
                      <option>Arquitectura </option>
                      <option>Arte</option>
                      <option>
                        Auditoría empresarial y del sector público
                      </option>
                      <option>
                        Bibliotecología y ciencias de la información
                      </option>
                      <option>Biología</option>
                      <option>Ciencia de la computación </option>
                      <option>Ciencia política</option>
                      <option>Ciencias biológicas</option>
                      <option>Ciencias de los alimentos</option>
                      <option>Computación científica</option>
                      <option>Comunicación social</option>
                      <option>Conservación y restauración</option>
                      <option>Contabilidad</option>
                      <option>Danza</option>
                      <option>Derecho</option>
                      <option>Economía</option>
                      <option>Educación</option>
                      <option>Educación física</option>
                      <option>Enfermería</option>
                      <option>Estadística</option>
                      <option>Farmacia y bioquímica</option>
                      <option>Filosofía</option>
                      <option>Física</option>
                      <option>Genética y biotecnología</option>
                      <option>Geografía</option>
                      <option>Gestión tributaria</option>
                      <option>Historia</option>
                      <option>Ingeniería agroindustrial</option>
                      <option>Ingeniería alimentaria</option>
                      <option>Ingeniería ambiental</option>
                      <option>Ingeniería biomédica</option>
                      <option>Ingeniería civil</option>
                      <option>
                        Ingeniería de higiene y seguridad industrial{' '}
                      </option>
                      <option>Ingeniería de minas</option>
                      <option>Ingeniería de petróleo y gas natural </option>
                      <option>
                        Ingeniería de seguridad y salud en el trabajo
                      </option>
                      <option>Ingeniería de sistemas </option>
                      <option>Ingeniería de software</option>
                      <option>Ingeniería de telecomunicaciones </option>
                      <option>Ingeniería de transportes</option>
                      <option>Ingeniería económica </option>
                      <option>Ingeniería eléctrica</option>
                      <option>Ingeniería electrónica</option>
                      <option>Ingeniería en acuicultura</option>
                      <option>Ingeniería en ecoturismo</option>
                      <option>Ingeniería estadística </option>
                      <option>Ingeniería física </option>
                      <option>Ingeniería geográfica</option>
                      <option>Ingeniería geológica</option>
                      <option>Ingeniería industrial</option>
                      <option>Ingeniería informática</option>
                      <option>Ingeniería mecánica </option>
                      <option>Ingeniería mecánica de fluidos</option>
                      <option>Ingeniería mecánica-eléctrica </option>
                      <option>Ingeniería mecatrónica</option>
                      <option>Ingeniería metalúrgica</option>
                      <option>Ingeniería naval </option>
                      <option>Ingeniería pesquera</option>
                      <option>Ingeniería petroquímica </option>
                      <option>Ingeniería química</option>
                      <option>Ingeniería sanitaria </option>
                      <option>Ingeniería textil </option>
                      <option>Investigación operativa</option>
                      <option>Laboratorio clínico y anatomía patológica</option>
                      <option>Lingüística</option>
                      <option>Literatura</option>
                      <option>Marketing</option>
                      <option>Matemática</option>
                      <option>Medicina humana</option>
                      <option>Medicina veterinaria</option>
                      <option>Microbiología y parasitología</option>
                      <option>Negocios internacionales</option>
                      <option>Nutrición</option>
                      <option>Obstetricia</option>
                      <option>Odontología</option>
                      <option>Optometría</option>
                      <option>Psicología</option>
                      <option>Química</option>
                      <option>Radiología</option>
                      <option>Sociología</option>
                      <option>Terapia de lenguaje</option>
                      <option>Terapia física y rehabilitación</option>
                      <option>Terapia ocupacional</option>
                      <option>Toxicología</option>
                      <option>Trabajo social</option>
                      <option>Otros</option>
                    </select>
                  </div>
                  <ErrorMessage errors={errors} field="study_career" />
                </div>
              </>
            )}
            {!['Pre-Universitario', 'Universitario'].includes(studyLevel) && (
              <div className="col-span-2" />
            )}
            <div className="col-span-2">
              <label htmlFor="inp_address" className="sr-only">
                Dirección
              </label>
              <div className="mt-1">
                <input
                  id="inp_address"
                  name="address"
                  type="text"
                  placeholder="Dirección"
                  className="inp-base"
                  {...register('address', { required: true })}
                ></input>
              </div>
              <ErrorMessage errors={errors} field="address" />
            </div>
            <div className="col-span-2">
              <label htmlFor="inp_department" className="sr-only">
                Departamento
              </label>
              <div className="mt-1">
                <select
                  id="inp_department"
                  name="department"
                  className="inp-base"
                  {...register('department', { required: true })}
                >
                  <option value="">Departamento</option>
                  {departments.map((obj) => (
                    <option key={obj.id} value={`${obj.id}|${obj.name}`}>
                      {obj.name}
                    </option>
                  ))}
                </select>
              </div>
              <ErrorMessage errors={errors} field="department" />
            </div>
            <div className="col-span-2">
              <label htmlFor="inp_province" className="sr-only">
                Provincia
              </label>
              <div className="mt-1">
                <select
                  id="inp_province"
                  name="province"
                  className="inp-base"
                  {...register('province', { required: true })}
                >
                  <option value="">Provincia</option>
                  {provinces.map((obj) => (
                    <option key={obj.id} value={`${obj.id}|${obj.name}`}>
                      {obj.name}
                    </option>
                  ))}
                </select>
              </div>
              <ErrorMessage errors={errors} field="province" />
            </div>
            <div className="col-span-2">
              <label htmlFor="inp_district" className="sr-only">
                Distrito
              </label>
              <div className="mt-1">
                <select
                  id="inp_district"
                  name="district"
                  className="inp-base"
                  {...register('district', { required: true })}
                >
                  <option value="">Distrito</option>
                  {districts.map((obj) => (
                    <option key={obj.id} value={`${obj.id}|${obj.name}`}>
                      {obj.name}
                    </option>
                  ))}
                </select>
              </div>
              <ErrorMessage errors={errors} field="district" />
            </div>
            {(!needsProxy || !showProxyForm) && (
              <>
                <div className="col-span-4 flex items-center">
                  <input
                    id="remember_me"
                    name="remember_me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                  />
                  <label
                    htmlFor="remember_me"
                    className="ml-2 block text-sm text-blue-600 underline"
                  >
                    Acepto los términos y condiciones
                  </label>
                </div>
                <div className="col-span-4 pt-4 text-center">
                  <SolidButton
                    onClick={
                      needsProxy
                        ? () => setShowProxyForm(true)
                        : handleSubmit(signup)
                    }
                    isLoading={loading}
                    className="mx-auto max-w-xs"
                  >
                    {needsProxy ? 'CONTINUAR' : 'REGISTRAR'}
                  </SolidButton>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Transition
        show={Boolean(needsProxy && showProxyForm)}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="z-10 py-10 lg:w-1/2 lg:py-20 lg:px-2 xl:px-12"
      >
        <div className="bg-white px-4 py-11 shadow lg:rounded-2xl lg:px-12">
          <div className="lg:mx-auto lg:w-full lg:max-w-md">
            <h2 className="text-center text-2xl font-light text-primary">
              DATOS DEL APODERADO
            </h2>
          </div>

          <div className="mt-16 gap-4 space-y-2 md:grid md:grid-cols-4 md:space-y-0">
            <div className="col-span-2">
              <label htmlFor="inp_proxy_doc_type" className="sr-only">
                Tipo de documento
              </label>
              <div className="mt-1">
                <select
                  id="inp_proxy_doc_type"
                  name="proxy_doc_type"
                  placeholder="Tipo de documento"
                  className="inp-base"
                  {...register('proxy_doc_type', {
                    required: needsProxy,
                  })}
                >
                  <option value="">Elegir tipo de documento</option>
                  <option>DNI</option>
                  <option>Pasaporte</option>
                  <option>Carnet de extranjería</option>
                  <option>RUC</option>
                </select>
              </div>
              <ErrorMessage errors={errors} field="proxy_doc_type" />
            </div>
            {proxyDocType === 'DNI' && <div className="col-span-2"></div>}
            <div className="col-span-2">
              <label htmlFor="inp_proxy_doc_number" className="sr-only">
                Número de documento
              </label>
              <div className="mt-1">
                <input
                  id="inp_proxy_doc_number"
                  name="proxy_doc_number"
                  type="text"
                  placeholder="Número de documento"
                  className="inp-base"
                  {...register('proxy_doc_number', {
                    required: needsProxy,
                  })}
                ></input>
              </div>
              <ErrorMessage errors={errors} field="proxy_doc_number" />
            </div>
            {proxyDocType === 'DNI' && (
              <div className="col-span-2">
                <OutlineButton
                  onClick={() => checkDNIProxy('proxy_')}
                  isLoading={checkLoading}
                  disabled={loading}
                  color="light-blue"
                >
                  VALIDAR
                </OutlineButton>
              </div>
            )}
            <div className="col-span-2">
              <label htmlFor="inp_proxy_name" className="sr-only">
                Nombre
              </label>
              <div className="mt-1">
                <input
                  id="inp_proxy_name"
                  name="proxy_name"
                  type="text"
                  placeholder="Nombre"
                  className="inp-base"
                  {...register('proxy_name', {
                    required: needsProxy,
                  })}
                ></input>
              </div>
              <ErrorMessage errors={errors} field="proxy_name" />
            </div>
            <div className="col-span-2">
              <label htmlFor="inp_proxy_last_name_p" className="sr-only">
                Apellido Paterno
              </label>
              <div className="mt-1">
                <input
                  id="inp_proxy_last_name_p"
                  name="proxy_last_name_p"
                  type="text"
                  placeholder="Apellido Paterno"
                  className="inp-base"
                  {...register('proxy_last_name_p', {
                    required: needsProxy,
                  })}
                ></input>
              </div>
              <ErrorMessage errors={errors} field="proxy_last_name_p" />
            </div>
            <div className="col-span-2">
              <label htmlFor="inp_proxy_last_name_m" className="sr-only">
                Apellido Materno
              </label>
              <div className="mt-1">
                <input
                  id="inp_proxy_last_name_m"
                  name="proxy_last_name_m"
                  type="text"
                  placeholder="Apellido Materno"
                  className="inp-base"
                  {...register('proxy_last_name_m', {
                    required: needsProxy,
                  })}
                ></input>
              </div>
              <ErrorMessage errors={errors} field="proxy_last_name_m" />
            </div>
            <div className="col-span-2">
              <label htmlFor="inp_proxy_phone" className="sr-only">
                Celular
              </label>
              <div className="mt-1">
                <input
                  id="inp_proxy_phone"
                  name="proxy_phone"
                  type="text"
                  placeholder="Celular"
                  className="inp-base"
                  {...register('proxy_phone', {
                    required: needsProxy,
                  })}
                ></input>
              </div>
              <ErrorMessage errors={errors} field="proxy_phone" />
            </div>
            <div className="col-span-2">
              <label htmlFor="inp_proxy_email" className="sr-only">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="inp_proxy_email"
                  name="proxy_email"
                  type="email"
                  placeholder="Email"
                  className="inp-base"
                  {...register('proxy_email', {
                    required: needsProxy,
                  })}
                ></input>
              </div>
              <ErrorMessage errors={errors} field="proxy_email" />
            </div>
            <div className="col-span-2">
              <label htmlFor="inp_proxy_relation" className="sr-only">
                Parentesco
              </label>
              <div className="mt-1">
                <select
                  id="inp_proxy_relation"
                  name="proxy_relation"
                  placeholder="Parentesco"
                  className="inp-base"
                  {...register('proxy_relation', { required: needsProxy })}
                >
                  <option value="">Elegir parentesco</option>
                  <option>Padre</option>
                  <option>Madre</option>
                  <option>Padrastro/Madrastra</option>
                  <option>Hermano(a)</option>
                  <option>Tío(a)</option>
                  <option>Tutor(a)</option>
                </select>
              </div>
              <ErrorMessage errors={errors} field="proxy_relation" />
            </div>
            <div className="col-span-4 flex items-center">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
              />
              <label
                htmlFor="remember_me"
                className="ml-2 block text-sm text-blue-600 underline"
              >
                Acepto los términos y condiciones
              </label>
            </div>
            <div className="col-span-4 pt-4 text-center">
              <SolidButton
                onClick={handleSubmit(signup)}
                isLoading={loading}
                className="mx-auto max-w-xs"
              >
                FINALIZAR REGISTRO
              </SolidButton>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
